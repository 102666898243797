


//
// VARIABLES
// ---------------------------
$breakpoint-xs: 1199px;
$breakpoint-sm: 1200px;
$breakpoint-md: 1200px;
$breakpoint-lg: 1200px;

.champ-team {
    background: rgba(251, 90, 0, .3);
}

.red-team {
    background: rgba(255,0,0,.3);
}

.blue-team {
    background: rgba(0, 0, 255, .3);
}

.green-team {
    background: rgba(0, 255, 0, .3);
}

.yellow-team {
    background: rgba(248, 213, 72, .3);
}

.white-team {
    background: rgba(255,255,255,.3);
}

.black-team {
    background: rgba(0,0,0,.3);
}

.purple-team {
    background: rgba(75, 0, 128, .3);
}

.brown-team {
    background: rgba(90, 23, 19, .3);
}




.bracket-holder {
  width: 100%;
  margin-bottom: 100px;
}

h1, h2 { 
  text-align: center;
}

h1 { 
  font-size: 2rem; 
  font-weight: 700;
  margin-bottom: 0.5em;
}

h2 { 
  font-size: 1.4rem; 
  font-weight: 600;
   margin-bottom: 2em;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}


//
// TOURNAMENT BRACKET
// ---------------------------
.tournament-bracket {
  display: flex;
  flex-direction: column;
  
  @media (min-width: $breakpoint-sm) {
    flex-direction: row;
  }
}

.tournament-bracket__round {
  display: block;
  margin-left: -3px;
  @media (min-width: $breakpoint-sm) {
        width: 22%;

        &.tournament-bracket__round--quarterfinals {
            width: 34%;
        }
    }
}

.tournament-bracket__round-title {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.5em;
}

.tournament-bracket__list {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  border-bottom: 1px dashed #e5e5e5;
  transition: padding 0.2s ease-in-out, margin 0.2s ease-in-out;
  margin: 0 0 2em 0;
  padding: 0 0 2em 0;
  
  @media (max-width: $breakpoint-xs) {
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
  
  @media (min-width: $breakpoint-sm) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
    margin: 0; 
    padding: 0;
  }
  
  .tournament-bracket__round:last-child & {
    border: 0;
  }
}

.tournament-bracket__item {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 2% 0;
  @media (min-width: $breakpoint-sm) {
    width: 48%;
  }
  transition: padding 0.2s linear;
  
  @media (min-width: $breakpoint-sm) {
  &:nth-child(odd) {
    margin-right: 2%;
  }
  
  &:nth-child(even) {
    margin-left: 2%;
  }
}
  
  &::after {
    transition: width 0.2s linear;
  }
  
  @media (max-width: $breakpoint-xs) {
    width: 100%;
    
    &:nth-child(odd),
    &:nth-child(even) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  @media (min-width: $breakpoint-sm) {
    padding: 0.5em 1em;
    // flex-grow: 2;
    width: 100%;
    
    &:nth-child(odd),
    &:nth-child(even) {
      margin: 0;
    }
    
    &::after {
      position: absolute;
      right: 0;
      content: '';
      display: block;
      width: 1em;
      height: 45%;
      border-right: 2px solid #1c6ea4;
    }

    &:nth-child(odd)::after {
      top: 50%;
      border-top: 2px solid #1c6ea4;
      transform: translateY(-1px);
      
      .tournament-bracket--rounded & {
        border-top-right-radius: 0.6em;
      }
    }
    
    &:nth-child(even)::after {
      bottom: 50%;
      border-bottom: 2px solid #1c6ea4;
      transform: translateY(1px);
      
      .tournament-bracket--rounded & {
        border-bottom-right-radius: 0.6em;
      }
    }
    .tournament-bracket__round:first-child & {
       padding-left: 0;
    }
    .tournament-bracket__round:last-child & {
       padding-right: 0;

       &::after {
         display: none;
       }
    }

    .tournament-bracket__round:nth-last-child(2) & {
      &::after {
        border-radius: 0;
        border-right: 0;
      }
    }  
  }
  
  @media (min-width: $breakpoint-lg) {
    padding: 0.5em 10px;
    
    &::after {
      width: 10px;
    }
  }
}

.bracket-match__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    color: #fff;
    font-weight: bold;
    text-shadow: 2px 2px 2px rgb(0 0 0 / 90%);

    &.semi,
    &.final,
    &.champ {
        .left-team,
        .right-team {
            width: 100%;
            text-align: center;
        }

        .left-team {
            margin-bottom: 5px;
        }
    }

    h5 {
        width: 100%;
        padding: 0;
        margin: 3px 0;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
    }

    .left-team,
    .right-team {
        width: 49%;
        padding: 5px;

        p {
            padding: 0;
            font-size: 16px;
            margin: 0 0 5px 0;

        }

        &.lost {
            //text-decoration: double line-through red;
            //-webkit-text-decoration: double line-through red;
            border: 3px solid #ff0000;
            border-radius: 5px;

          background-image: repeating-linear-gradient(to top left, transparent 0%, transparent 47%, #ff0000 50%, transparent 53%, transparent 100%), repeating-linear-gradient(to top right, transparent 0%, transparent 47%, #ff0000 50%, transparent 53%, transparent 100%);
        }

        &.won  {
            border: 3px solid #00ff00;
            border-radius: 5px;
        }
    }

    .left-team {
     
    }

    .right-team {
        
    }
}




.tournament-bracket__match {
  display: flex;
  width: 100%;
  background: url(../images/bg.gif);
    border: 2px solid #565341;
    border-radius: 10px;
  padding: 5px;
  border-radius: 0.1em;
  box-shadow: 2px 2px 2px rgba(0,0,0, .3);
  outline: none; 
  cursor: pointer;
  transition: padding 0.2s ease-in-out, border 0.2s linear;
  
  &:focus {
    border-color: #2196F3;
  }
  
  &::before,
  &::after {
    transition: all 0.2s linear;
  }
  
  @media (max-width: $breakpoint-xs) {
    padding: 0.75em 0.5em;
  }
  
  @media (min-width: $breakpoint-sm) {
    &::before,
    &::after {
      position: absolute;
      left: 0;
      z-index: 1;
      content: '';
      display: block;
      width: 1em;
      height: 10%;
      border-left: 2px solid #1c6ea4;
    }

    &::before  {
      bottom: 50%;
      border-bottom: 2px solid #1c6ea4;
      transform: translate(0, 1px);
      
      .tournament-bracket--rounded & {
        border-bottom-left-radius: 0.6em;
      }
    }

    &::after  {
      top: 50%;
      border-top: 2px solid #1c6ea4;
      transform: translate(0, -1px);
      
      .tournament-bracket--rounded & {
        border-top-left-radius: 0.6em;
      }
    }
  }
  
  @media (min-width: $breakpoint-lg) {
    &::before,
    &::after {
      width: 10px;
    }
    
    &::before {
      transform: translate(0, 1px);
    }
    
    &::after {
      transform: translate(0, -1px);
    }
  }
}

.tournament-bracket__round:last-child .tournament-bracket__match {
  &::before,
  &::after {
    border-left: 0;
  }
  
  &::before  {
    border-bottom-left-radius: 0;
  }
  
  &::after  {
    display: none;
  }
}

.tournament-bracket__round:first-child .tournament-bracket__match {
  &::before,
  &::after {
    display: none;
  }
}

.tournament-bracket__content {
  display: flex;
  
  &::after {
    content: ':';
    width: 1em;
    text-align: center;
    padding: 0.2em 0.1em;
    
    @media (min-width: $breakpoint-sm) {
       order: 1;
    }
  }
  
  & .tournament-bracket__team:first-child {
    width: 50%;
    order: 0;
    text-align: right;
    
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
      align-items: flex-end;
    }
    
    & .tournament-bracket__country {
      order: 2;
      justify-content: flex-end;
      
      @media (min-width: $breakpoint-xs) {
        order: 0;
      } 
      
      @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }
    
    & .tournament-bracket__score {
      order: 0;
      
      @media (min-width: $breakpoint-xs) {
         order: 2;
      }
    }
  }
  
  & .tournament-bracket__team:last-child {
    width: 50%;
    order: 2;
    text-align: left;
    
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
      align-items: flex-start;
    }
    
    & .tournament-bracket__country {
      @media (min-width: $breakpoint-sm) {
        justify-content: flex-start;
      }
      
      @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        align-items: flex-start;
      }
    }
    
    .tournament-bracket__code {
      order: 1;
    }
  }
}


.tournament-bracket__table {
  width: 100%;
}

.tournament-bracket__caption {
  font-size: 0.8rem;
  color: #BDBDBD;
  font-weight: 300;
  padding-bottom: 0.75em;
}

.tournament-bracket__team {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  
  @media (min-width: $breakpoint-xs) {
    flex-direction: column-reverse;
  }
  
  @media (min-width: $breakpoint-sm) {
    flex-direction: column-reverse;
  }
}

.tournament-bracket__country {
  font-size: 0.95rem;
  display: flex;
  margin-top: 0.5em;
  align-items: center;
  
  @media (max-width: $breakpoint-xs) {
    margin-top: 0;
  }
  
  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    
    .tournament-bracket__code {
      margin-top: 0.2em;
    }
  }
}

.tournament-bracket__code {
  padding: 0 0.5em;
  color: #212121;
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  text-decoration: none;
  cursor: help;
  transition: padding 0.2s ease-in-out;
  
  @media (max-width: $breakpoint-xs) {
    padding: 0 0.25em;
  }
  
  @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    padding: 0;
  }
}

.tournament-bracket__score {
  display: flex;
  align-items: center;
  
  .tournament-bracket__team:first-child & {
    flex-direction: row-reverse;
    padding-left: 0.75em;
  }
  
  .tournament-bracket__team:last-child & {
    padding-right: 0.75em;
  }
}

.tournament-bracket__number {
  display: inline-block;
  padding: 0.2em 0.4em 0.2em;
  border-bottom: 0.075em solid transparent;
  font-size: 0.95rem;
  background-color: #F5F5F5;
  border-color: spin(shade(#F5F5F5, 10%), -10);
  
  .tournament-bracket__team--winner & {
    background-color: #FFF176;
    border-color: spin(shade(#FFF176, 2%), -10);
  }
}

.tournament-bracket__medal {
  padding: 0 0.5em;
}

.tournament-bracket__medal--gold {
  color: #FFD700;
}

.tournament-bracket__medal--silver {
  color: #C0C0C0;
}

.tournament-bracket__medal--bronze {
  color: #CD7F32;
}