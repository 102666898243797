.champ-team {
  background: #fb5a004d;
}

.red-team {
  background: #ff00004d;
}

.blue-team {
  background: #0000ff4d;
}

.green-team {
  background: #00ff004d;
}

.yellow-team {
  background: #f8d5484d;
}

.white-team {
  background: #ffffff4d;
}

.black-team {
  background: #0000004d;
}

.purple-team {
  background: #4b00804d;
}

.brown-team {
  background: #5a17134d;
}

.bracket-holder {
  width: 100%;
  margin-bottom: 100px;
}

h1, h2 {
  text-align: center;
}

h1 {
  margin-bottom: .5em;
  font-size: 2rem;
  font-weight: 700;
}

h2 {
  margin-bottom: 2em;
  font-size: 1.4rem;
  font-weight: 600;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.tournament-bracket {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1200px) {
  .tournament-bracket {
    flex-direction: row;
  }
}

.tournament-bracket__round {
  margin-left: -3px;
  display: block;
}

@media (min-width: 1200px) {
  .tournament-bracket__round {
    width: 22%;
  }

  .tournament-bracket__round.tournament-bracket__round--quarterfinals {
    width: 34%;
  }
}

.tournament-bracket__round-title {
  color: #fff;
  text-align: center;
  margin-bottom: .5em;
  font-size: 20px;
  font-weight: 400;
}

.tournament-bracket__list {
  height: 100%;
  min-height: 100%;
  border-bottom: 1px dashed #e5e5e5;
  flex-flow: wrap;
  justify-content: center;
  margin: 0 0 2em;
  padding: 0 0 2em;
  transition: padding .2s ease-in-out, margin .2s ease-in-out;
  display: flex;
}

@media (max-width: 1199px) {
  .tournament-bracket__list {
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__list {
    border-bottom: 0;
    margin: 0;
    padding: 0;
  }
}

.tournament-bracket__round:last-child .tournament-bracket__list {
  border: 0;
}

.tournament-bracket__item {
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  padding: 2% 0;
  transition: padding .2s linear;
  display: flex;
  position: relative;
}

@media (min-width: 1200px) {
  .tournament-bracket__item {
    width: 48%;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__item:nth-child(2n+1) {
    margin-right: 2%;
  }

  .tournament-bracket__item:nth-child(2n) {
    margin-left: 2%;
  }
}

.tournament-bracket__item:after {
  transition: width .2s linear;
}

@media (max-width: 1199px) {
  .tournament-bracket__item {
    width: 100%;
  }

  .tournament-bracket__item:nth-child(2n+1), .tournament-bracket__item:nth-child(2n) {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__item {
    width: 100%;
    padding: .5em 1em;
  }

  .tournament-bracket__item:nth-child(2n+1), .tournament-bracket__item:nth-child(2n) {
    margin: 0;
  }

  .tournament-bracket__item:after {
    content: "";
    width: 1em;
    height: 45%;
    border-right: 2px solid #1c6ea4;
    display: block;
    position: absolute;
    right: 0;
  }

  .tournament-bracket__item:nth-child(2n+1):after {
    border-top: 2px solid #1c6ea4;
    top: 50%;
    transform: translateY(-1px);
  }

  .tournament-bracket--rounded .tournament-bracket__item:nth-child(2n+1):after {
    border-top-right-radius: .6em;
  }

  .tournament-bracket__item:nth-child(2n):after {
    border-bottom: 2px solid #1c6ea4;
    bottom: 50%;
    transform: translateY(1px);
  }

  .tournament-bracket--rounded .tournament-bracket__item:nth-child(2n):after {
    border-bottom-right-radius: .6em;
  }

  .tournament-bracket__round:first-child .tournament-bracket__item {
    padding-left: 0;
  }

  .tournament-bracket__round:last-child .tournament-bracket__item {
    padding-right: 0;
  }

  .tournament-bracket__round:last-child .tournament-bracket__item:after {
    display: none;
  }

  .tournament-bracket__round:nth-last-child(2) .tournament-bracket__item:after {
    border-right: 0;
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__item {
    padding: .5em 10px;
  }

  .tournament-bracket__item:after {
    width: 10px;
  }
}

.bracket-match__content {
  width: 100%;
  color: #fff;
  text-shadow: 2px 2px 2px #000000e6;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: bold;
  display: flex;
}

.bracket-match__content.semi .left-team, .bracket-match__content.semi .right-team, .bracket-match__content.final .left-team, .bracket-match__content.final .right-team, .bracket-match__content.champ .left-team, .bracket-match__content.champ .right-team {
  width: 100%;
  text-align: center;
}

.bracket-match__content.semi .left-team, .bracket-match__content.final .left-team, .bracket-match__content.champ .left-team {
  margin-bottom: 5px;
}

.bracket-match__content h5 {
  width: 100%;
  text-align: center;
  margin: 3px 0;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
}

.bracket-match__content .left-team, .bracket-match__content .right-team {
  width: 49%;
  padding: 5px;
}

.bracket-match__content .left-team p, .bracket-match__content .right-team p {
  margin: 0 0 5px;
  padding: 0;
  font-size: 16px;
}

.bracket-match__content .left-team.lost, .bracket-match__content .right-team.lost {
  background-image: repeating-linear-gradient(to top left, #0000 0% 47%, red 50%, #0000 53% 100%), repeating-linear-gradient(to top right, #0000 0% 47%, red 50%, #0000 53% 100%);
  border: 3px solid red;
  border-radius: 5px;
}

.bracket-match__content .left-team.won, .bracket-match__content .right-team.won {
  border: 3px solid #0f0;
  border-radius: 5px;
}

.tournament-bracket__match {
  width: 100%;
  cursor: pointer;
  background: url("bg.377f5627.gif");
  border: 2px solid #565341;
  border-radius: .1em;
  outline: none;
  padding: 5px;
  transition: padding .2s ease-in-out, border .2s linear;
  display: flex;
  box-shadow: 2px 2px 2px #0000004d;
}

.tournament-bracket__match:focus {
  border-color: #2196f3;
}

.tournament-bracket__match:before, .tournament-bracket__match:after {
  transition: all .2s linear;
}

@media (max-width: 1199px) {
  .tournament-bracket__match {
    padding: .75em .5em;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__match:before, .tournament-bracket__match:after {
    z-index: 1;
    content: "";
    width: 1em;
    height: 10%;
    border-left: 2px solid #1c6ea4;
    display: block;
    position: absolute;
    left: 0;
  }

  .tournament-bracket__match:before {
    border-bottom: 2px solid #1c6ea4;
    bottom: 50%;
    transform: translate(0, 1px);
  }

  .tournament-bracket--rounded .tournament-bracket__match:before {
    border-bottom-left-radius: .6em;
  }

  .tournament-bracket__match:after {
    border-top: 2px solid #1c6ea4;
    top: 50%;
    transform: translate(0, -1px);
  }

  .tournament-bracket--rounded .tournament-bracket__match:after {
    border-top-left-radius: .6em;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__match:before, .tournament-bracket__match:after {
    width: 10px;
  }

  .tournament-bracket__match:before {
    transform: translate(0, 1px);
  }

  .tournament-bracket__match:after {
    transform: translate(0, -1px);
  }
}

.tournament-bracket__round:last-child .tournament-bracket__match:before, .tournament-bracket__round:last-child .tournament-bracket__match:after {
  border-left: 0;
}

.tournament-bracket__round:last-child .tournament-bracket__match:before {
  border-bottom-left-radius: 0;
}

.tournament-bracket__round:last-child .tournament-bracket__match:after, .tournament-bracket__round:first-child .tournament-bracket__match:before, .tournament-bracket__round:first-child .tournament-bracket__match:after {
  display: none;
}

.tournament-bracket__content {
  display: flex;
}

.tournament-bracket__content:after {
  content: ":";
  width: 1em;
  text-align: center;
  padding: .2em .1em;
}

@media (min-width: 1200px) {
  .tournament-bracket__content:after {
    order: 1;
  }
}

.tournament-bracket__content .tournament-bracket__team:first-child {
  width: 50%;
  text-align: right;
  order: 0;
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .tournament-bracket__content .tournament-bracket__team:first-child {
    align-items: flex-end;
  }
}

.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__country {
  order: 2;
  justify-content: flex-end;
}

@media (min-width: 1199px) {
  .tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__country {
    order: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__country {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__score {
  order: 0;
}

@media (min-width: 1199px) {
  .tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__score {
    order: 2;
  }
}

.tournament-bracket__content .tournament-bracket__team:last-child {
  width: 50%;
  text-align: left;
  order: 2;
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .tournament-bracket__content .tournament-bracket__team:last-child {
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__country {
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__country {
    align-items: flex-start;
  }
}

.tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__code {
  order: 1;
}

.tournament-bracket__table {
  width: 100%;
}

.tournament-bracket__caption {
  color: #bdbdbd;
  padding-bottom: .75em;
  font-size: .8rem;
  font-weight: 300;
}

.tournament-bracket__team {
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 1199px) {
  .tournament-bracket__team {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1200px) {
  .tournament-bracket__team {
    flex-direction: column-reverse;
  }
}

.tournament-bracket__country {
  align-items: center;
  margin-top: .5em;
  font-size: .95rem;
  display: flex;
}

@media (max-width: 1199px) {
  .tournament-bracket__country {
    margin-top: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .tournament-bracket__country {
    flex-direction: column;
    display: flex;
  }

  .tournament-bracket__country .tournament-bracket__code {
    margin-top: .2em;
  }
}

.tournament-bracket__code {
  color: #212121;
  text-transform: uppercase;
  cursor: help;
  border: 0;
  padding: 0 .5em;
  font-weight: 600;
  text-decoration: none;
  transition: padding .2s ease-in-out;
}

@media (max-width: 1199px) {
  .tournament-bracket__code {
    padding: 0 .25em;
  }
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .tournament-bracket__code {
    padding: 0;
  }
}

.tournament-bracket__score {
  align-items: center;
  display: flex;
}

.tournament-bracket__team:first-child .tournament-bracket__score {
  flex-direction: row-reverse;
  padding-left: .75em;
}

.tournament-bracket__team:last-child .tournament-bracket__score {
  padding-right: .75em;
}

.tournament-bracket__number {
  border-bottom: .075em solid #0000;
  border-color: spin(shade(#f5f5f5, 10%), -10);
  background-color: #f5f5f5;
  padding: .2em .4em;
  font-size: .95rem;
  display: inline-block;
}

.tournament-bracket__team--winner .tournament-bracket__number {
  border-color: spin(shade(#fff176, 2%), -10);
  background-color: #fff176;
}

.tournament-bracket__medal {
  padding: 0 .5em;
}

.tournament-bracket__medal--gold {
  color: gold;
}

.tournament-bracket__medal--silver {
  color: silver;
}

.tournament-bracket__medal--bronze {
  color: #cd7f32;
}

/*# sourceMappingURL=index.3c2960f0.css.map */
